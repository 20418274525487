import PropTypes from "prop-types";
import React from "react";
import Container from "react-bootstrap/Container";
import Layout from "../../components/layout";
import SEO from "../../components/seo";

const TransparentOutdoorLed = () => {
    return (
        <Layout>
            <SEO
                title={""}
                description={""}
                lang={"id"}
            />
            <section id={"produk"} className={"section-bg"}>
                <Container>
                    <div className={"section-title"}>
                        <h1>Transparent Outdoor LED Display Modules</h1>
                    </div>
                </Container>
            </section>
        </Layout>
    );
};

TransparentOutdoorLed.propTypes = {
    siteTitle: PropTypes.string,
};

TransparentOutdoorLed.defaultProps = {
    siteTitle: ``,
};

export default TransparentOutdoorLed;
